import React from 'react';
import { motion } from 'framer-motion';

const pythonImage = require('../static/images/PythonLogo.png');
const javaImage = require('../static/images/JavaLogo.png');
const JSImage = require('../static/images/JSLogo.png');
const ReactImage = require('../static/images/ReactLogo.png');
const ReactNativeImage = require('../static/images/ReactNativeLogo.png');
const CImage = require('../static/images/CLogo.png');
const SQLImage = require('../static/images/SQLLogo.png');
const MongodbImage = require('../static/images/MongodbLogo.png');
const OtherImage = require('../static/images/OtherLogo.png');
const placeholderImage = require('../static/images/placeholder.webp');

const hooliImage = require('../static/images/projects/hooli.jpeg');
const snakeImage = require('../static/images/projects/snake.webp');
const dbImage = require('../static/images/projects/db.webp');
const terminalImage = require('../static/images/projects/terminal.webp');
const analyzerImage = require('../static/images/projects/analyzer.webp');
const minerImage = require('../static/images/projects/hash.webp');
const tetrisImage = require('../static/images/projects/tetris.webp');
const bookstoreImage = require('../static/images/projects/bookstore.webp');
const gameImage = require('../static/images/projects/game.webp');
const theaterImage = require('../static/images/projects/theater.webp');
const portfolioImage = require('../static/images/projects/portfolio.png');
const droneImage = require('../static/images/projects/drone.webp');
const ecoImage = require('../static/images/projects/eco.webp');
const videocallImage = require('../static/images/projects/videocall.webp');
const languageImage = require('../static/images/projects/language.webp');
const movieImage = require('../static/images/projects/movie.webp');
const reversiImage = require('../static/images/projects/reversi.webp');

export default function Card(props) {
    const languageImage = getImage(props.image);
    const projectImage  = getProjectImage(props.projectImage);

    return (
        <motion.div
            className="card"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => Open(props.code)}
        >
            <div className="card-image">
                <img src={projectImage} alt={props.projectImage} />
                <div className="language-icon">
                    <img src={languageImage} alt={props.image} />
                </div>
            </div>
            <div className="card-content">
                <h3>{props.title}</h3>
                <p className="card-description">{props.desc}</p>
                <div className={`card-code ${props.code !== "placeholder" ? "available" : ""}`}>
                    <p>{props.code !== "placeholder" ? "Click to see the code!" : "Code not available"}</p>
                </div>
            </div>
        </motion.div>
    );
}


function Open(url) {
    if (url !== "placeholder")
        window.open(url, "_blank");
}

function getImage(type) {
    if (type === 'C') {
        return CImage;
    }
    if (type === 'Python') {
        return pythonImage;
    }
    if (type === 'Java') {
        return javaImage;
    }
    if (type === 'JS') {
        return JSImage;
    }
    if (type === 'React') {
        return ReactImage;
    }
    if (type === 'ReactNative') {
        return ReactNativeImage;
    }
    if (type === 'SQL') {
        return SQLImage;
    }
    if (type === 'Mongodb') {
        return MongodbImage;
    }
    return OtherImage;

}

function getProjectImage(project) {
    if (project === 'hooli') {
        return hooliImage;
    } else if (project === 'snake') {
        return snakeImage;
    } else if (project === 'db') {
        return dbImage;
    } else if (project === 'terminal') {
        return terminalImage;
    }
    else if (project === 'analyzer') {
        return analyzerImage;
    }
    else if (project === 'miner') {
        return minerImage;
    }
    else if (project === 'tetris') {
        return tetrisImage;
    }
    else if (project === 'bookstore') {
        return bookstoreImage;
    }
    else if (project === 'game') {
        return gameImage;
    }
    else if (project === 'theater') {
        return theaterImage;
    }
    else if (project === 'portfolio') {
        return portfolioImage;
    }
    else if (project === 'drone') {
        return droneImage;
    }
    else if (project === 'eco') {
        return ecoImage;
    }
    else if (project === 'videocall') {
        return videocallImage;
    }
    else if (project === 'language') {
        return languageImage;
    }
    else if (project === 'movie') {
        return movieImage;
    }
    else if (project === 'reversi') {
        return reversiImage;
    }
    return placeholderImage;
}