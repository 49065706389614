
export default function Navbar() {
    const navbar = (
            <ul className="navbar">
                <li key="1"><a href="#home">Home</a></li>
                <li key="2"><a href="#about">About</a></li>
                <li key="3"><a href="#technologies">Technologies</a></li>
                <li key="4"><a href="#projects">Projects</a></li>
                <li key="5"><a href="#education">Education</a></li>
                <li key="6"><a href="#contact">Contact</a></li>

            </ul>
    );
    return navbar;
}