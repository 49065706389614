
import Card from "./Card";
import projects from "../projects.json"
import React from 'react';
import { motion } from 'framer-motion';
import { FaEnvelope, FaLinkedin, FaGithub, FaFileAlt } from 'react-icons/fa';
import { FaGraduationCap, FaCalendarAlt } from 'react-icons/fa';
import { FaReact, FaNodeJs, FaPython, FaJava, FaDatabase } from 'react-icons/fa';
import { SiJavascript, SiMongodb, SiDotnet } from 'react-icons/si';
import { SiTensorflow, SiPytorch, SiC, SiRust, SiCplusplus, SiPostgresql, SiRedis, SiAzurepipelines } from 'react-icons/si';
import Navbar from './Navbar';

import '../static/App.css';
import '../static/css/home.css';
import '../static/css/nav.css';
import '../static/css/tech.css';
import '../static/css/about.css';
import '../static/css/projects.css';
import '../static/css/edu.css';
import '../static/css/contact.css';

/*
const pythonImage = require('../static/images/PythonLogo.png');
const javaImage = require('../static/images/JavaLogo.png');
const JSImage = require('../static/images/JSLogo.png');
const ReactImage = require('../static/images/ReactLogo.png');
const ReactNativeImage = require('../static/images/ReactNativeLogo.png');
const CImage = require('../static/images/CLogo.png');
const SQLImage = require('../static/images/SQLLogo.png');
const MongodbImage = require('../static/images/MongodbLogo.png');
const CSharpImage = require('../static/images/CSharpLogo.png');
const RustImage = require('../static/images/RustLogo.png');
*/

// const eps = require('../static/images/eps.jpg');
// const uc3m = require('../static/images/uc3m.png');
const foto = require('../static/images/fotoPortfolio.jpg');


export default function Main() {

    const title = (
        <section id="home">
            <div className="home">
                <h2>Hello, I am Guillermo.</h2>
                <div className='home-container'>
                    <span>I am a </span>
                    <ul>
                        <li key="o1"><span className="orange">Computer Scientist</span></li>
                        <li key="o2"><span className="orange">Software Engineer</span></li>
                        <li key="o3"><span className="orange">AI enthusiast</span></li>
                        <li key="o4"><span className="orange">Hard worker</span></li>
                        <li key="o5"><span className="orange">fast learner</span></li>
                        <li key="o6"><span className="orange">hard-working person</span></li>
                        <li key="o7"><span className="orange">team player</span></li>
                        <li key="o8"><span className="orange">problem solver</span></li>
                        <li key="o9"><span className="orange">innovative thinker</span></li>
                        <li key="o10"><span className="orange">detail-oriented person</span></li>
                        <li key="o11"><span className="orange">creative developer</span></li>
                        <li key="o12"><span className="orange">data analyst</span></li>
                        <li key="o13"><span className="orange">project manager</span></li>
                        <li key="o14"><span className="orange">software designer</span></li>
                        <li key="o15"><span className="orange">full-stack developer</span></li>
                        <li key="o16"><span className="orange">machine learning specialist</span></li>
                    </ul>
                </div>
            </div>
        </section>
    );

    const about = (
        <section id="about">
            <div className='about'>
                <h2>About Me</h2>
                <hr />

                <div className="about-container">
                    <img src={foto} alt="Foto de perfil" className="foto" />
                    <div>
                        {getParagraph()}
                        <hr />
                    </div>
                </div>

            </div>
        </section>
    );

    const TechCategory = ({ title, skills }) => (
        <motion.div
            className="tech-category"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <h3>{title}</h3>
            <div className="skills-container">
                {skills.map((skill, index) => (
                    <motion.div
                        key={index}
                        className="skill"
                        whileHover={{ scale: 1.05 }}
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                        <a href={skill.link} target="_blank" rel="noopener noreferrer" className="skill-link">
                            <div className="skill-info">
                                {skill.icon}
                                <span>{skill.name}</span>
                            </div>
                        </a>
                    </motion.div>
                ))}
            </div>
        </motion.div>
    );

    const Technologies = () => {
        const categories = [
            {
                title: "System Programming",
                skills: [
                    { name: "C", icon: <SiC />, link: "https://en.cppreference.com/w/c" },
                    { name: "Rust", icon: <SiRust />, link: "https://doc.rust-lang.org/book/" },
                    { name: "C++", icon: <SiCplusplus />, link: "https://en.cppreference.com/w/cpp" },
                    { name: "Java", icon: <FaJava />, link: "https://docs.oracle.com/en/java/" },
                ]
            },
            {
                title: "Database Technologies",
                skills: [
                    { name: "MongoDB", icon: <SiMongodb />, link: "https://docs.mongodb.com/" },
                    { name: "SQL", icon: <FaDatabase />, link: "https://www.w3schools.com/sql/" },
                    { name: "PostgreSQL", icon: <SiPostgresql />, link: "https://www.postgresql.org/docs/" },
                    { name: "Redis", icon: <SiRedis />, link: "https://redis.io/documentation" },
                ]
            },
            {
                title: "Web Technologies",
                skills: [
                    { name: "React", icon: <FaReact />, link: "https://reactjs.org/docs/getting-started.html" },
                    { name: "JavaScript", icon: <SiJavascript />, link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide" },
                    { name: "Node.js", icon: <FaNodeJs />, link: "https://nodejs.org/en/docs/" },
                    { name: "Azure Pipelines", icon: <SiAzurepipelines />, link: "https://docs.microsoft.com/en-us/azure/devops/pipelines/?view=azure-devops" },
                    { name: ".Net", icon: <SiDotnet />, link: "https://docs.microsoft.com/en-us/dotnet/" },
                ]
            },
            {
                title: "AI Technologies",
                skills: [
                    { name: "Python", icon: <FaPython />, link: "https://docs.python.org/3/" },
                    { name: "TensorFlow", icon: <SiTensorflow />, link: "https://www.tensorflow.org/learn" },
                    { name: "PyTorch", icon: <SiPytorch />, link: "https://pytorch.org/docs/stable/index.html" },
                ]
            },


        ];

        return (
            <section id="technologies">
                <motion.div
                    className="technologies"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2>Technologies</h2>
                    <hr />
                    {categories.map((category, index) => (
                        <TechCategory key={index} {...category} />
                    ))}
                </motion.div>
            </section>
        );
    };

    const Projects = () => {
        const [currentIndex, setCurrentIndex] = React.useState(0);
        const [elementsToShow, setElementsToShow] = React.useState(4);
        const projectElements = getProjectElements();

        const updateElementsToShow = () => {
            const width = window.innerWidth;
            if (width >= 1200) {
                setElementsToShow(4);
            } else if (width >= 992) {
                setElementsToShow(3);
            } else if (width >= 768) {
                setElementsToShow(2);
            } else {
                setElementsToShow(1);
            }
        };

        React.useEffect(() => {
            updateElementsToShow();
            window.addEventListener('resize', updateElementsToShow);
            return () => window.removeEventListener('resize', updateElementsToShow);
        }, []);

        const nextProject = () => {
            setCurrentIndex((prevIndex) =>
                prevIndex + elementsToShow >= projectElements.length ? 0 : prevIndex + elementsToShow
            );
        };

        const prevProject = () => {
            setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? projectElements.length - elementsToShow : prevIndex - elementsToShow
            );
        };

        const getVisibleElements = () => {
            const endIndex = currentIndex + elementsToShow;
            if (endIndex > projectElements.length) {
                return [
                    ...projectElements.slice(currentIndex, projectElements.length),
                    ...projectElements.slice(0, endIndex - projectElements.length)
                ];
            }
            return projectElements.slice(currentIndex, endIndex);
        };

        return (
            <section id="projects">
                <div className="projects">
                    <h2>Projects</h2>
                    <hr />
                    <div className="carousel-container">
                        <button className="carousel-button prev" onClick={prevProject}>&lt;</button>
                        <motion.div
                            className="carousel"
                            key={currentIndex}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            {getVisibleElements().map((element, index) => (
                                <div key={index} className="carousel-item">
                                    {element}
                                </div>
                            ))}
                        </motion.div>
                        <button className="carousel-button next" onClick={nextProject}>&gt;</button>
                    </div>
                </div>
            </section>
        );
    };

    const EducationItem = ({ image, university, degree, years }) => (
        <motion.div
            className="education-item"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <motion.img
                src={image}
                alt={university}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
            />
            <div className="education-details">
                <h3>{university}</h3>
                <p><FaGraduationCap /> {degree}</p>
                <p><FaCalendarAlt /> {years}</p>
            </div>
        </motion.div>
    );

    const Education = () => {
        const educationData = [
            {
                image: require('../static/images/eps.jpg'),
                university: "Universidad Autónoma de Madrid, Spain",
                degree: "Ingeniería Informática (Computer Science)",
                years: "2019 - 2023"
            },
            {
                image: require('../static/images/uc3m.png'),
                university: "Universidad Carlos III de Madrid, Spain",
                degree: "Máster en Ingeniería Informática (Master in Computer Science)",
                years: "2023 - 2024"
            }
        ];

        return (
            <section id="education">
                <motion.div
                    className="education"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2>Education</h2>
                    <hr />
                    <div className="education-container">
                        {educationData.map((item, index) => (
                            <EducationItem key={index} {...item} />
                        ))}
                    </div>
                </motion.div>
            </section>
        );
    };


    const Contact = () => {
        const contactItems = [
            { icon: <FaEnvelope />, text: 'guillermo.martincoello@gmail.com', link: 'mailto:guillermo.martincoello@gmail.com' },
            { icon: <FaLinkedin />, text: 'LinkedIn', link: 'https://www.linkedin.com/in/guillemcj/' },
            { icon: <FaGithub />, text: 'GitHub', link: 'https://github.com/Gmarcoel' },
            { icon: <FaFileAlt />, text: 'CV English', link: '#', onClick: () => CVDownload("CV_en") }
        ];

        return (

            <section id="contact">
                <Navbar />

                <motion.div
                    className="contact"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2>Contact</h2>
                    <hr />
                    <motion.h2
                        className="orange"
                        initial={{ scale: 0.9 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.5, yoyo: Infinity, ease: "easeInOut" }}
                    >
                        Guillermo Martín-Coello Juárez
                    </motion.h2>
                    <div className="contact-container">
                        {contactItems.map((item, index) => (
                            <motion.a
                                key={index}
                                href={item.link}
                                className="contact-item"
                                onClick={item.onClick}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                            >
                                {item.icon}
                                <span>{item.text}</span>
                            </motion.a>
                        ))}
                    </div>
                </motion.div>
            </section>
        );
    };


    return [
        <div key="title">{title}</div>,
        <div key="about">{about}</div>,
        <Technologies key="technologies" />,
        <Projects key="projects" />,
        <Education key="education" />,
        <Contact key="contact" />
    ];
}

const CVDownload = (name) => {
    // using Java Script method to get PDF file
    fetch(name + '.pdf').then(response => {
        console.log(response)
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = name + '.pdf';
            alink.click();
        })
    })
}



function getParagraph() {
    return (
        <p>
            <span>
                As I said before, my name is Guillermo and I study a double master in Computer Science (ingeniería informática) and Artificial Inteligence at
            </span>
            <span> </span>
            <a className="orange" href="https://www.uam.es/uam/inicio">
                Universidad Carlos III de Madrid
            </a>
            <span>, Spain.
                I am also a self-taught programmer, extending my knowledge on different fields related to software development. I am an Arch Linux user and I love
                to learn about new technologies. I consider myself a hard-working person and a team player. I am a fast learner and I have leadership habilities.
                I am a very social person and I enjoy meeting new people.
            </span>
        </p>
    );

}


function getProjectElements() {
    const projectsElements = projects.map(project => {
        return (
            <Card
                key={project.title}
                title={project.title}
                desc={project.desc}
                image={project.image}
                code={project.code}
                projectImage={project["back-image"]}
            />
        );
    });
    return projectsElements;
};