import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Typography, Container, Card, CardContent, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Brightness4, Brightness7, ContentCopy, ArrowBack } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../static/css/blog.css';
import { List, ListItem, ListItemText } from '@mui/material';



const BlogDetail = () => {
    const { id } = useParams();
    const location = useLocation();
    const [darkMode, setDarkMode] = useState(location.state?.darkMode || false);
    const [blogContent, setBlogContent] = useState('');

    const navigate = useNavigate();

    const handleReturn = () => {
        navigate('/blog');
    };

    useEffect(() => {
        fetch(`/blog-posts/${id}.md`)
            .then(response => response.text())
            .then(data => setBlogContent(data))
            .catch(error => console.error('Error fetching blog post:', error));
    }, [id]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
    });

    const CodeBlock = ({ language, value }) => {
        const [copied, setCopied] = useState(false);

        const handleCopy = () => {
            navigator.clipboard.writeText(value);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        };

        return (
            <div className="code-block" style={{ position: 'relative', borderRadius: '5px' }}>
                <Button
                    size="small"
                    startIcon={<ContentCopy />}
                    onClick={handleCopy}
                    className="copy-button"

                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        zIndex: 1,
                        color: 'white !important',
                    }}
                >
                    {copied ? 'Copied!' : 'Copy'}
                </Button>
                <SyntaxHighlighter language={language} style={tomorrow}>
                    {value}
                </SyntaxHighlighter>
            </div>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div data-theme={darkMode ? 'dark' : 'light'} className="BlogContainer theme-transition">
                <AppBar position="fixed" sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            150 Days of Code
                        </Typography>
                        <IconButton onClick={handleReturn} color="inherit">
                            <ArrowBack />
                        </IconButton>
                        <IconButton sx={{ ml: 1 }} onClick={toggleDarkMode} color="inherit">
                            {darkMode ? <Brightness7 /> : <Brightness4 />}
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="md">
                    <Card sx={{ mt: 4, mb: 4 }} className="post-content">
                        <CardContent>

                            <ReactMarkdown
                                components={{
                                    
                                    h3: ({ children }) => (
                                        <Typography variant="h5" gutterBottom>
                                            {children}
                                        </Typography>
                                    ),



                                    ol: ({ children }) => (
                                        <List sx={{
                                            listStyleType: "decimal",
                                            mt: 2,
                                            pl: 2,
                                            "& .MuiListItem-root": {
                                                display: "list-item",
                                            },
                                        }}>
                                            {children}
                                        </List>
                                    ),
                                    ul: ({ children }) => (
                                        <List sx={{
                                            listStyleType: "circle",
                                            mt: 2,
                                            pl: 2,
                                            "& .MuiListItem-root": {
                                                display: "list-item",
                                            },
                                        }}>
                                            {children}
                                        </List>
                                    ),
                                    li: ({ children, ...props }) => (
                                        <ListItem sx={{ m: 0, p: 0, ml: 2 }} disableGutters>
                                            <ListItemText sx={{ pl: 0.25 }}>{children}</ListItemText>
                                        </ListItem>
                                    ),


                                    code: ({ node, inline, className, children, ...props }) => {
                                        const match = /language-(\w+)/.exec(className || '');
                                        return !inline && match ? (
                                            <CodeBlock
                                                language={match[1]}
                                                value={String(children).replace(/\n$/, '')}
                                                {...props}
                                            />
                                        ) : (
                                            <code className={className} {...props}>
                                                {children}
                                            </code>
                                        );
                                    },
                                }}
                            >
                                {blogContent}
                            </ReactMarkdown>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        </ThemeProvider>
    );
};

export default BlogDetail;