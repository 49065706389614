import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import '../static/css/blog.css';
import { Route, Routes, Link } from 'react-router-dom';
//import BlogDetail from './BlogDetail';

const BlogPost = ({ title, date, content }) => (
    <Card sx={{ mb: 2 }}>
        <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
                {title}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                {date}
            </Typography>
            <Typography variant="body1">{content}</Typography>
        </CardContent>
    </Card>
);

const BlogComponent = () => {
    const [darkMode, setDarkMode] = useState(false);

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
    });

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const [blogPosts, setBlogPosts] = useState([]);


    useEffect(() => {
        fetch('/blogPosts.json')
            .then(response => response.json())
            .then(data => setBlogPosts(data))
            .catch(error => console.error('Error fetching blog posts:', error));
    }, []);


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div data-theme={darkMode ? 'dark' : 'light'} className="BlogContainer">
                <CssBaseline />
                <AppBar position="fixed" sx={{ boxShadow: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            150 Days of Code
                        </Typography>
                        <IconButton sx={{ ml: 1 }} onClick={toggleDarkMode} color="inherit">
                            {darkMode ? <Brightness7 /> : <Brightness4 />}
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Container maxWidth="md" style={{ paddingTop: '100px' }}>
                    <Box sx={{ my: 4, background: 'none' }}>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <div>
                                        {blogPosts.map((post) => (
                                            <Link key={post.id} to={`/blog/${post.id}`} state={{ darkMode }} style={{ textDecoration: 'none' }}>
                                                <BlogPost key={post.id} {...post} />
                                            </Link>
                                        ))}
                                    </div>
                                }
                            />
                        </Routes>
                    </Box>
                </Container>
            </div>
        </ThemeProvider>
    );
};


export default BlogComponent;